import { getValueFromDto as getValue, getArrayFromDto as getArray } from './_helpers.js';

export default class WorkOrderMapLocation {
	constructor(dto) {
		this.customerLocationId = getValue(dto, 'customerLocationId', 'number', null);
		this.latitude = getValue(dto, 'latitude', 'number', null);
		this.longitude = getValue(dto, 'longitude', 'number', null);
		this.workOrderTypeIds = getArray(dto, 'workOrderTypeIds', []).map(x => x === 0 ? null : x);
		this.wasteTypeIds = getArray(dto, 'wasteTypeIds', []).map(x => x === 0 ? null : x);
		this.userIds = getArray(dto, 'userIds', []).map(x => x === 0 ? null : x);
		this.vehicleIds = getArray(dto, 'vehicleIds', []).map(x => x === 0 ? null : x);
	}
}
