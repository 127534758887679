import { url as urlHelper } from '@/helpers/helpers';
import { getFiltered, getIdb, idbHelpers, localChanges, localChangesInUse } from '@/idb';
import Customer from '@/models/Customer';
import Inventory from '@/models/Inventory';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import OperationResponse from '@/models/OperationResponse';
import PaginatedList from '@/models/PaginatedList';
import WorkOrder from '@/models/WorkOrder';
import WorkOrderMapLocation from '@/models/WorkOrderMapLocation';
import WorkOrderStatus from '@/models/WorkOrderStatus';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isAborted, isIdbResponse, offlineResponse, setPagination } from '../_helpers';
import filesApi from './files';

async function replaceLocalChanges(idb, data, filter) {
	return localChanges.replaceDataIfChanged(idb, 'workOrders', data, filter, (target, source) => copyCustomer(source, target));
}

async function addLocalChanges(idb, data, localChangesOnly, filter) {
	if (!idb) return;
	const needsCustomer = [];
	await localChanges.addDataFromIdb(idb, 'workOrders', data, localChangesOnly, filter, (target, source) => {
		if (source) {
			copyCustomer(source, target);
		} else {
			needsCustomer.push(target);
		}
	});
	await setCustomers(idb, needsCustomer);
}

function cleanDataForIdb(data) {
	return data.map(x => {
		const y = cleanDataItemForIdb(x);
		return y;
	});
}

const keysToRemove = ['customer', 'customerLocation', 'payment', 'user', 'vehicle', 'wasteTypeCollected', 'wasteDisposal'];
function cleanDataItemForIdb(x) {
	const y = Object.assign({}, x);
	for (const key of keysToRemove) {
		delete y[key];
	}
	// maybe delete y.serviceItems[i].service
	// maybe delete y.inventoryItems[i].inventory
	return y;
}

async function storeInIdb(idb, data) {
	const added = await idbHelpers.replaceAllExisting(idb, 'workOrders', cleanDataForIdb(data));
	const fileMap = {};
	added.forEach(x => x.attachments.forEach(y => fileMap[y.id] = y));
	await filesApi.storeInIdb(Object.values(fileMap));
}

async function storeItemInIdb(idb, data) {
	if (await idbHelpers.putIfExists(idb, 'workOrders', cleanDataItemForIdb(data))) {
		await filesApi.storeInIdb(data.attachments);
	}
}

async function copyCustomer(source, target) {
	if (target.customerId === source.customerId) {
		target.customer = source.customer;
		target.customerLocation = null;
	}
}

async function setCustomers(idb, data) {
	const customerMap = {};
	for (const item of data) {
		const c = customerMap[item.customerId] ?? (customerMap[item.customerId] = await idb.get('customers', item.customerId));
		item.customer = c ? new Customer(c) : null;
		item.customerLocation = null;
	}
}

export default {
	idbReplaceLocalChanges: replaceLocalChanges,
	/**
	 * Get paginated work orders
	 * @param {Object} params request parameters.
	 * @returns {Promise<PaginatedList<WorkOrder>>} (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getPaginated({
		status = undefined, hasReminder = undefined, isPaid = undefined, workOrderTypeId = undefined,
		startDate = undefined, endDate = undefined,
		wasteTypeId = undefined, userId = undefined, vehicleId = undefined,
		sort = undefined,
		limit = undefined, start = undefined
	} = {}) {
		const allowedSorts = ['date-desc', 'date-asc', 'date-reminder-desc', 'date-reminder-asc'];
		const query = setPagination(limit, start);
		if (typeof status === 'number') {
			query.status = status;
		}
		if (typeof workOrderTypeId === 'number') {
			query.workOrderTypeId = workOrderTypeId;
		}
		if (typeof wasteTypeId === 'number') {
			query.wasteTypeId = wasteTypeId;
		}
		if (typeof userId === 'number') {
			query.userId = userId;
		}
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (typeof hasReminder === 'boolean') {
			query.hasReminder = hasReminder;
		}
		if (typeof isPaid === 'boolean') {
			query.isPaid = isPaid;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		if (typeof sort === 'string' && allowedSorts.includes(sort)) {
			query.sort = sort;
		} else {
			query.sort = allowedSorts[0];
		}
		const url = urlHelper('/api/WorkOrders', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get recent work orders with estimate status, paginated.
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getRecentEstimates({ limit = undefined, start = undefined } = {}) {
		const url = urlHelper('/api/WorkOrders/RecentEstimates', setPagination(limit, start));
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders assigned to the current user in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllMyWork({ startDate = undefined, endDate = undefined, userId }) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		} else {
			startDate = undefined;
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		} else {
			endDate = undefined;
		}
		const url = urlHelper('/api/WorkOrders/ForSelf', query);
		const idbFilter = x => {
			if ((x.status === WorkOrderStatus.workOrder || x.status === WorkOrderStatus.invoice) && x.userId === userId && x.vehicleId !== null && x.scheduledStartTime !== null) {
				const scheduledStartTime = DateTime.fromISO(x.scheduledStartTime);
				return (!startDate || scheduledStartTime >= startDate) && (!endDate || scheduledStartTime < endDate);
			}
		};
		let response, data = [];
		try {
			response = await fetchAllPages(url, async x => data.push(x));
		} catch {
			if (idb) {
				await addLocalChanges(idb, data = [], false, idbFilter);
				response = idbResponse(200);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await storeInIdb(idb, data);
				await replaceLocalChanges(idb, data, idbFilter);
				await addLocalChanges(idb, data, true, idbFilter);
			}
			data = data.map(x => new WorkOrder(x));
			if (idb) {
				data.sort((a, b) => {
					const time = a.scheduledStartTime - b.scheduledStartTime;
					if (time !== 0) { return time; }
					if (a.scheduledStartTimeType !== b.scheduledStartTimeType) {
						return a.scheduledStartTimeType < b.scheduledStartTimeType ? 1 : -1; // descending
					}
					return 0;
				});
				if (!isIdbResponse(response)) {
					// if we see a new work order that should be in the idb but isn't, resync
					const idbEndDate = DateTime.now().startOf('day').plus({ days: 1 });
					const idbStartDate = idbEndDate.plus({ days: -4 });
					const woIds = new Set(await idb.getAllKeys('workOrders'));
					for (const x of data) {
						if (navigator.serviceWorker && !woIds.has(x.id) && x.scheduledStartTime >= idbStartDate && x.scheduledStartTime < idbEndDate) {
							navigator.serviceWorker.ready.then(function (worker) {
								worker.active.postMessage('sync-local-changes');
							});
							break;
						}
					}
				}
			}
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders scheduled in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForDispatch({ startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForDispatch', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new WorkOrder(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get a list of inital location data for google map
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrderMapLocation[]>} (async) Returns an array of objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async forDispatchMapBasic({ startDate = undefined, endDate = undefined } = {}, abortSignal) {
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForDispatchMapBasic', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return (await response.json()).map(x => new WorkOrderMapLocation(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders at the specified customer location scheduled in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getForDispatchMapDetails({ customerLocationId, startDate = undefined, endDate = undefined } = {}, abortSignal) {
		const query = {};
		query.customerLocationId = customerLocationId;
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForDispatchMapDetails', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return (await response.json()).map(x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get work orders with the specified ids
	 * @param {Number[]} ids work order ids.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getByIds(ids) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		let response, data = [];
		try {
			response = await fetchWrap('/api/WorkOrders/ByIds', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(ids),
			});
			if (response.ok) {
				data = await response.json();
			}
		} catch {
			if (idb) {
				const idsSet = new Set(ids);
				await addLocalChanges(idb, data = [], false, x => idsSet.has(x.id));
				data.sort((a, b) => a.id - b.id);
				response = idbResponse(200);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await storeInIdb(idb, data);
				await replaceLocalChanges(idb, data);
			}
			return data.map(x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders completed by the specified vehicle which have not been disposed of.
	 * @param {Object} param0 request parameters.
	 * @param {Number} param0.vehicleId
	 * @param {Number} param0.wasteDisposalId
	 * @param {Number[]} param0.ids
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForDisposal({ vehicleId, wasteDisposalId = 0, ids = null }) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		const query = {};
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		const url = urlHelper('/api/WorkOrders/ForDisposal', query);
		const idSet = new Set(wasteDisposalId < 0 && Array.isArray(ids) ? ids : []);
		const idbFilter = x => (x.workStartTime != null && x.vehicleId === vehicleId && x.wasteTypeCollectedId != null && x.amountCollected > 0 && (x.wasteDisposalId === null || (wasteDisposalId < 0 && x.wasteDisposalId === wasteDisposalId))) || idSet.has(x.id);
		let response, data = [];
		try {
			response = await fetchAllPages(url, async x => data.push(x));
		} catch {
			if (idb) {
				await addLocalChanges(idb, data = [], false, idbFilter);
				response = idbResponse(200);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await storeInIdb(idb, data);
				await replaceLocalChanges(idb, data, idbFilter);
				await addLocalChanges(idb, data, true, idbFilter);
			}
			if (!isIdbResponse(response) && wasteDisposalId < 0 && idSet.size > 0) {
				const missingIds = ids.filter(x => !data.find(y => y.id === x));
				if (missingIds.length > 0) {
					const extraData = await this.getByIds(missingIds);
					data = data.concat(extraData);
				}
			}
			data = data.map(x => x instanceof WorkOrder ? x : new WorkOrder(x));
			if (idb) {
				data.sort((a, b) => a.workStartTime - b.workStartTime);
			}
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders completed by the specified vehicle in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForVehicleReport({ vehicleId, startDate, endDate }) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		const query = {};
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		} else {
			startDate = undefined;
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		} else {
			endDate = undefined;
		}
		const url = urlHelper('/api/WorkOrders/ForVehicleReport', query);
		const idbFilter = x => {
			if (x.workStartTime !== null && x.vehicleId == query.vehicleId && x.wasteTypeCollectedId != null && x.amountCollected > 0) {
				const workStartTime = DateTime.fromISO(x.workStartTime);
				return workStartTime >= startDate && workStartTime < endDate;
			}
		};
		let response, data = [];
		try {
			response = await fetchAllPages(url, async x => data.push(x));
		} catch {
			if (idb) {
				await addLocalChanges(idb, data = [], false, idbFilter);
				response = idbResponse(200);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await storeInIdb(idb, data);
				await replaceLocalChanges(idb, data, idbFilter);
				await addLocalChanges(idb, data, true, idbFilter);
			}
			data = data.map(x => new WorkOrder(x));
			if (idb) {
				data.sort((a, b) => a.workStartTime - b.workStartTime);
			}
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders completed by the specified vehicle in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForVehicleDisposalLog({ vehicleId, startDate, endDate }) {
		const query = {};
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForVehicleDisposalLog', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new WorkOrder(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated work orders for the specified customer (and optionally location) in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<PaginatedList<WorkOrder>>} (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getPaginatedForCustomer({ customerId, customerLocationId = undefined, status = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (typeof status === 'number') {
			query.status = status;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForCustomer', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all work orders for the specified customer (and optionally location) in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForCustomer({ customerId, customerLocationId = undefined, status = undefined, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (typeof status === 'number') {
			query.status = status;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForCustomer', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new WorkOrder(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated work orders for the specified customer rental
	 * @param {Object} params request parameters.
	 * @returns {Promise<PaginatedList<WorkOrder>>} (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getPaginatedForRental({ customerRentalId, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerRentalId === 'number') {
			query.customerRentalId = customerRentalId;
		}

		const url = urlHelper('/api/WorkOrders/ForRental', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get the previous completed work order at this customer location
	 * @param {Number} id WorkOrder ID
	 * @returns {WorkOrder|null} (async) Returns a WorkOrder if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getPrevious(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/Previous/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			if (response.status === 200) {
				return new WorkOrder(await response.json());
			} else {
				return null;
			}
		} else if (response.status === 404 || response.status === 503) {
			return null;
		} else {
			throw response;
		}
	},
	/**
	 * Get all unpaid work orders for the specified customer in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<WorkOrder[]>} (async) Returns an array of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForCustomerUnpaid({ customerId, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrders/ForCustomerUnpaid', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new WorkOrder(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get work orders reminders, paginated.
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getReminders({ limit = undefined, start = undefined } = {}) {
		const url = urlHelper('/api/WorkOrders/Reminders', setPagination(limit, start));
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get work orders past due, paginated.
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getPastDue({ limit = undefined, start = undefined } = {}) {
		const url = urlHelper('/api/WorkOrders/PastDue', setPagination(limit, start));
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrder(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a work order
	 * @param {Number} id WorkOrder ID
	 * @returns {Promise<WorkOrder>} (async) Returns a WorkOrder if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getById(id) {
		let response, data;
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (idb) { ({ response, data } = await localChanges.getDataIfChanged(idb, 'workOrders', id)); }
		try {
			if (!response) {
				response = await fetchWrap('/api/WorkOrders/' + id);
				if (response.ok) { data = await response.json(); }
			}
		} catch {
			if (idb) {
				data = await idb.get('workOrders', id);
				response = data ? idbResponse(200) : idbResponse(404);
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				storeItemInIdb(idb, data);
			}
			return new WorkOrder(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a work order
	 * @param {WorkOrder} model work order to create.
	 * @returns (async) Returns the new WorkOrder if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(WorkOrder.makeDtio(model)),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new WorkOrder(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Dismiss work order reminder
	 * @param {Number} id id of work order to update
	 * @returns (async) Returns the Response.
	 */
	async dismissReminder(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/DismissReminder/' + id, { method: 'PUT' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Update work order interest
	 * @param {Number} id id of work order to update
	 * @param {Number} interest new interest amount
	 * @returns (async) Returns the updated WorkOrder if the request was successful, otherwise a Response.
	 */
	async updateInterest(id, interest) {
		const model = { interest };
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/Interest/' + id, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new WorkOrder(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update worker order invoice date
	 * @param {Number} id id of work order to update
	 * @param {DateTime}  new invoiceDate
	 * @return (async) Returns the updated WorkOrder if the request was succesful, otherwise a Response
	 */
	async updateInvoiceDate(id, invoiceDate) {
		const model = { invoiceDate };
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/InvoiceDate/' + id, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});

		} catch {
			response = offlineResponse();
		}

		if (response.ok) {
			return new WorkOrder(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Mark a completed work order incomplete.
	 * @param {Number} id id of work order to update
	 * @returns (async) Returns the Response.
	 */
	async markInvoiceIncomplete(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/MarkInvoiceIncomplete/' + id, { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Update a work order
	 * @param {WorkOrder} model work order to update.
	 * @param {WorkOrder} oldModel old work order to determine updates to apply.
	 * @param {Boolean} resubmitting set to true if this update is being resubmitted from idb localChanges.
	 * @returns (async) Returns the updated WorkOrder if the request was successful, otherwise a Response.
	 */
	async update(model, oldModel, resubmitting) {
		if (!(model instanceof WorkOrder)) throw new Error('Argument error: expected WorkOrder instance but did not receive it');
		model.recalculate();
		const idb = localChangesInUse.value ? await getIdb() : null;
		await filesApi.mapCleanLocalIds(model.attachments);
		const newData = JSON.parse(JSON.stringify(WorkOrder.makeDtio(model)));
		let oldData = JSON.parse(JSON.stringify(WorkOrder.makeDtio(oldModel)));
		let change = null;
		if (idb) {
			change = await localChanges.get(idb, LocalChange.getKey('workOrders', model.id));
			if (!resubmitting && change && change.data.oldData && typeof change.data.oldData === 'object') {
				oldData = change.data.oldData;
			}
		}
		let response, data;
		let patch = jsonPatchCompare(oldData, newData);
		if (patch.length === 0) {
			return model;
		} else {
			try {
				response = await fetchWrap('/api/WorkOrders/' + model.id, {
					method: 'PATCH',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(patch),
				});
				if (response.ok) { data = await response.json(); }
			} catch {
				if (idb && !resubmitting && await idb.count('workOrders', model.id) > 0) {
					// store local change in idb
					if (!change) { change = new LocalChange({ storeName: 'workOrders', id: model.id, state: LocalChangeState.modified, data: {} }); }
					change.error = null;
					change.data.oldData = oldData;
					await localChanges.add(idb, change);
					data = JSON.parse(JSON.stringify(model));
					for (let i = 0; i < data.attachments.length; i++) {
						data.attachments[i] = model.attachments[i].toDto();
					}
					await storeItemInIdb(idb, data);

					// update inventory quantityReserved while offline
					const quantityChanges = {};
					for (const item of oldModel.inventoryItems) {
						if (item.inventoryId > 0) {
							quantityChanges[item.inventoryId] = (quantityChanges[item.inventoryId] ?? 0) - item.quantity;
						}
					}
					for (const item of model.inventoryItems) {
						if (item.inventoryId > 0) {
							quantityChanges[item.inventoryId] = (quantityChanges[item.inventoryId] ?? 0) + item.quantity;
						}
					}
					const idbInventory = await getFiltered(idb, 'inventory', x => x.id in quantityChanges, x => new Inventory(x));
					for (const idbItem of idbInventory) {
						if (idbItem.id in quantityChanges && quantityChanges[idbItem.id] !== 0) {
							idbItem.quantityReserved += quantityChanges[idbItem.id];
							await idb.put('inventory', JSON.parse(JSON.stringify(idbItem)));
							await localChanges.add(idb, new LocalChange({ storeName: 'inventory', id: idbItem.id, state: LocalChangeState.modifiedIndirectly }));
						}
					}
					response = idbResponse(204);
					data = model;
				} else {
					response = offlineResponse();
				}
			}
		}
		if (response.ok) {
			if (!isIdbResponse(response) && idb) {
				if (change) {
					await localChanges.deleteChange(idb, change.key);
					if ((change.state & LocalChangeState.modifiedIndirectly) === LocalChangeState.modifiedIndirectly) {
						data.isPaid = model.isPaid;
						data.wasteDisposalId = model.wasteDisposalId;
						await localChanges.add(idb, new LocalChange({ storeName: 'workOrders', id: model.id, state: LocalChangeState.modifiedIndirectly }));
					}
				}
				await storeItemInIdb(idb, data);
			}
			return new WorkOrder(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a work order
	 * @param {Number} id WorkOrder ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the work order could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	},
	/**
	 * check work order's rental operations
	 * @param {WorkOrder} model work order to check.
	 * @returns (async) Returns true if the request was successful, otherwise a Response includes a message.
	 */
	async checkOperation(model) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrders/CheckOperation', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new OperationResponse(data);
		} else {
			throw response;
		}
	}
};
