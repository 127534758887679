import { getValueFromDto as getValue } from './_helpers.js';
import PaymentType from './PaymentType.js';

export default class PaymentTerm {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.daysUntilDue = getValue(dto, 'daysUntilDue', 'number', 0);
		this.lateFeeInterestRate = getValue(dto, 'lateFeeInterestRate', 'number', 0);
		this.acceptedPaymentTypes = [];
		this.sortOrder = getValue(dto, 'sortOrder', 'number', 0);
		this.active = getValue(dto, 'active', 'boolean', true);

		if (typeof dto === 'object' && dto !== null && typeof dto.acceptedPaymentTypes === 'object' && Array.isArray(dto.acceptedPaymentTypes)) {
			// ensure no duplicates and no account balance
			const set = new Set(dto.acceptedPaymentTypes);
			if (set.has(PaymentType.accountBalance)) {
				set.delete(PaymentType.accountBalance);
			}
			this.acceptedPaymentTypes = Array.from(set);
		}
	}
}
