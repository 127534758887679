import { toDictionary } from '@/helpers/helpers';
import { imageFileExtensions } from '@/helpers/yupExtensions';
import { getIdb, localChanges, localChangesInUse, localIds } from '@/idb';
import FileReference from '@/models/FileReference';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import { DateTime } from 'luxon';
import { fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';

const idbStoreName = 'files';

export default {
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Files/' + id);
		} catch {
			throw offlineResponse();
		}
		if (response.ok) {
			return new FileReference(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 *
	 * @param {File} file
	 * @returns
	 */
	async upload(file, id) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		const formData = new FormData();
		formData.append('formFile', file);
		let response, data;
		try {
			response = await fetchWrap('/api/Files', {
				method: 'POST',
				body: formData,
			});
			data = await response.json();
			data.data = file;
		} catch (e) {
			if (idb) {
				if (id < 0) {
					data = await idb.get(idbStoreName, id);
					response = data ? idbResponse(200) : idbResponse(404);
				} else {
					data = {};
					data.id = await localIds.getNewId(idb, idbStoreName);
					data.name = file.name;
					data.size = file.size;
					data.url = FileReference.getUrl(data.id);
					data.uploadTimestamp = DateTime.now();
					data.data = file;
					await idb.put(idbStoreName, data);
					await localChanges.add(idb, new LocalChange({ storeName: idbStoreName, id: data.id, state: LocalChangeState.added }));
					response = idbResponse(200);
				}
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			if (!isIdbResponse(response) && idb) {
				await localIds.addLocalIdMap(idb, idbStoreName, id, data.id);
				if (id < 0) { await idb.delete(idbStoreName, id); }
				await idb.put(idbStoreName, data);
				await localChanges.deleteChange(idb, LocalChange.getKey(idbStoreName, id));
			}
			data.data = null;
			return new FileReference(data);
		} else {
			return response;
		}
	},
	/**
	 *
	 * @param {FileReference[]} files
	 */
	async storeInIdb(files, prune = false) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (!idb) return;
		if (files.length === 0) {
			if (prune) {
				await idb.clear(idbStoreName)
			}
			return;
		}
		const valueMap = toDictionary(files);
		let cursor = await idb.transaction(idbStoreName, 'readwrite').store.openCursor();
		while (cursor) {
			if (cursor.key in valueMap) {
				if (cursor.value.data) {
					delete valueMap[cursor.key];
				}
			} else if (cursor.key > 0) {
				if (prune) {
					await cursor.delete();
				} else if (!cursor.value.data) {
					valueMap[cursor.key] = cursor.value.data;
				}
			}
			cursor = await cursor.continue();
		}
		for (const value of Object.values(valueMap)) {
			const data = await getFileBlob(value);
			if (data) {
				value.data = data;
				await idb.put(idbStoreName, value);
				value.data = null;
			}
		}
	},
	/**
	 * Check attachments, map local IDs, and remove any files that can't exist on the server.
	 * @param {FileReference[]} files array to be modified
	 */
	async mapCleanLocalIds(files) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (!idb) return;
		const ids = new Set();
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			// map negative file IDs
			file.id = await localIds.mapLocalId(idb, idbStoreName, file.id);
			// remove invalid file IDs
			if (ids.has(file.id) || (file.id < 0 && !(await localChanges.get(idb, LocalChange.getKey(idbStoreName, file.id))))) {
				files.splice(i, 1);
				i--;
			} else {
				ids.add(file.id);
			}
		}
	},
};

async function getFileBlob(file) {
	if (file.url && imageFileExtensions.includes('.' + FileReference.getExtension(file.name))) {
		try {
			const response = await fetchWrap(file.url, { priority: 'low' });
			if (response.ok) {
				return await response.blob();
			}
		} catch { }
	}
}
